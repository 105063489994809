export const showAddressPrompt = currentAddress => {
  const html = `
  <div class="address_prompt" id="address_prompt">
    <i class="fas fa-map-marker-alt address_prompt__icon"></i>
    <div class="address_prompt__content">
      <h3 class="address_prompt__header">Is this the right delivery address?</h2>
      <span>${currentAddress}</span>
      <div class="address_prompt__links">
        <a class="address_prompt__link" onclick="document.getElementById('address_prompt').remove();nx.addToCart.openAccountsFrame();">Update address</a>
        <a class="address_prompt__link" onclick="document.getElementById('address_prompt').remove();">Dismiss</a>
      </div>
    </div>
  </div>`;
  const target = document.querySelector('.masthead_button--delivery');
  target.insertAdjacentHTML('beforeend', html);

}
