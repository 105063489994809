export const nextDialog = () => {
  if (!window.nx.dialogs.dialogQueue) return;
  const order = Object.keys(window.nx.dialogs.dialogQueue).sort((a, b) => a - b); // numeric
  if(!order.length) return;
  const nextNumber = order.shift();
  const next = window.nx.dialogs.dialogQueue[nextNumber];
  const func = next[0];
  const args = next[1];
  func(args);
  delete window.nx.dialogs.dialogQueue[nextNumber];
}
