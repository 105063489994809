export const openApplicationMenu = () => {
    let me = new window.nx.drawer.nxDrawer({
      content: document.querySelector('.applicationMenuContent').cloneNode(true),
      entry: 'right',
      size: '576',
      showClose: false,
      drawerCssClass: '',
      timeout: 'modal',
      afterOpen: function() {
        document.body.classList.add('no_scroll_application_menu');
      },
      afterClose: function() {
        document.body.classList.remove('no_scroll_application_menu');
      }
    });
    window.nx.navigation.closeApplicationMenu = me.close.bind(me);
}

