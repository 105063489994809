export const freeDeliveryNotification = () => {
    if (!nxDatalayer.global.postcode) return;
    const url = `/main-ajax-zonefreight?postcode=${nxDatalayer.global.postcode}`;
    fetch(url, {
        method: 'GET'
    }).then(response => {
        if (!response.ok) {
            throw Error('bad response');
        }
        return response.json();
    }).then(surcharges => {
        const holder = document.getElementById('free_delivery_notification');
        if (surcharges.length < 1) return;

        let threshold = 0;
        surcharges.forEach(surcharge => {
            if (surcharge.threshold_to > threshold) {
                threshold = surcharge.threshold_to;
            }
        });
        threshold = '' + threshold;
        const strLength = threshold.length;
        let decicents = threshold.substring(strLength - 1, strLength);
        decicents = parseInt(decicents, 10);
        let cents = threshold.substring(strLength - 2, 2);
        if (decicents > 4) cents = '' + (parseInt(cents, 10) + 1);

        const dollars = threshold.length > 3
            ? threshold.substring(0, strLength - 3)
            : 0;
        
        const html = `<a class="masthead__top_link" href="/page/delivery" 
            onclick="var s=s_gi(s_account);s.linkTrackVars='list2';s.linkTrackEvents='None';s.tl(this,'o','masthead:free delivery');
            ">
            <i class="fa fa-fw fa-truck" style="font-size:12px" aria-label="Delivery" role="img"></i>
            <span class="masthead__top_link_text">
                Free shipping when you spend $${dollars}.${cents}*
            </span>
        </a>`;
        holder.innerHTML = html;
    }).catch(error =>
    {
        // do nothing
    });
}

